frappe.provide("tetra.utils");

$.extend(tetra.utils, {
	logger: function() {
		console.log("Logger function from utils.js");
	}
});

frappe.form.link_formatters["Item"] = function (value, doc) {
	if (doc && value && doc.item_name && doc.item_name !== value && doc.item_code === value) {
		return doc.item_name + ": " + value;
	} else if (!value && doc.doctype && doc.item_name) {
		// format blank value in child table
		return doc.item_name;
	} else {
		// if value is blank in report view or item code and name are the same, return as is
		return value;
	}
};

frappe.form.link_formatters['Task'] = function(value, doc) {
	if(doc.task_name && doc.task_name !== value) {
		return value + ': ' + doc.task_name;
	}
	else {
		return value;
	}
}