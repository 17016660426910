frappe.provide("tetra.queries");

$.extend(tetra.queries, {
    purchase_items: function () {
        return {
            filters: {
                "buying": 1,
            },
        }
    },
    project_locations: function (project) {
        return {
            query: "tetra.controllers.queries.project_location_query",
            filters: {
                project: project
            }
        }
    },
    project_bom_items: function (filters) {
        let { project_bom, project, location } = filters;
        if (project_bom) {
            filters.project_bom = project_bom;
        }

        if (project) {
            filters.project = project;
        }

        if (location) {
            filters.location = location;
        }
        return {
            query: "tetra.controllers.queries.get_project_bom_items",
            filters: filters
        }
    }

});